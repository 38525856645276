import { BaseDataProvider } from "../../../../lib/baseDataProvider/base.dataProvider";
import { RequestError } from "../../../../lib/baseDataProvider/types";
import { ClientDetailedAddress, Company } from "../../../../lib/types";
import { Backup, GetBackupArchiveResponse, WorkPoint } from "../types/types";

export default class CompanyProfilePageDataProvider extends BaseDataProvider {
    public async getCompanyData(): Promise<Company> {
        const result = await this.launchGetRequest<Company>('api/companies/getUsersCompany');
        return result.data;
    }

    public async updateCompanyData(regComNo: string, address: string, iban: string, bankName: string, detailedAddress: ClientDetailedAddress, vatOnPayment: boolean, vatPayer: boolean) {
        await this.launchPostRequest('api/companies/updateCompanyData', { regComNo, address, iban, bankName, detailedAddress, vatOnPayment, vatPayer });
    }

    public async getCompanyWorkPoints(): Promise<WorkPoint[]> {
        const result = await this.launchGetRequest<WorkPoint[]>('api/endpoints/');
        return result.data;
    }

    public async updateCompanyWorkPoint(workPointId: string, workPointName: string): Promise<void> {
        await this.launchPostRequest('publicApi/endpoints/update', { payload: { endpointId: workPointId, endpointName: workPointName }});
    }

    public async getCompanyBackups(): Promise<Backup[]> {
        const result = await this.launchGetRequest<Backup[]>('api/backups/');
        return result.data;
    }

    public async downloadCompanyBackup(backupId: string): Promise<GetBackupArchiveResponse> {
        let response;

        response = await fetch(
            `api/backups/downloadBackup?backupId=${backupId}`, 
            {
                method: 'GET',
                headers: 
                {
                    'Accept': 'application/zip',
                    'x-session-id': localStorage.getItem('sessionId')!
                }
            }
        );

        if(!response.ok) {
            throw new RequestError("", response.status);
        }

        const filename = response.headers.get('content-disposition')!.substring(response.headers.get('content-disposition')!.indexOf("filename=") + 9);

        return {
            data: await response.blob(),
            filename: filename
        } as GetBackupArchiveResponse;
    }
    
    public async uploadCompanyLogo(fileData: string | ArrayBuffer | null, fileType: string): Promise<void> {
        await this.launchPostRequest('api/companies/uploadIcon', { fileData, fileType });
    }

    public async deleteCompanyLogo(): Promise<void> {
        await this.launchPostRequest('api/companies/deleteIcon', { });
    }
}